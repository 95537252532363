import React from "react";
import { Input, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { Props } from "./TradeLicenses.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";

export const TradeLicenses = (props: Props) => {
  const screen = translations.screens.profile;
  const { isUpdatingProfile, isEditMode, data, onEditField, formData } = props;
  const { plumbingLicense, septicSystemInspectionsLicense } = data || {};

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.septicPumping)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.plumbingLicense}
                  onChange={onEditField("plumbingLicense")}
                />
              ) : (
                plumbingLicense || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.septicInspection)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.septicSystemInspectionsLicense}
                  onChange={onEditField("septicSystemInspectionsLicense")}
                />
              ) : (
                septicSystemInspectionsLicense || "-"
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

import React, { useCallback } from "react";
import { PaymentsPresentation } from "./Payments.presentation";
import { useGetStripeLink } from "../../../../api/modules/GetStripeLink";

export const Payments = () => {
  const { mutateAsync, isLoading } = useGetStripeLink();

  const handleClickCTA = useCallback(async () => {
    const link = await mutateAsync();
    window.location.assign(link);
  }, [mutateAsync]);

  return (
    <PaymentsPresentation isLoading={isLoading} onClickCTA={handleClickCTA} />
  );
};

import { TRequestProps } from "../api/modules/AvailableJobs/types";

export const Endpoints = {
  getUser: "/api/user/profile",
  getCompany: (companyId: string | number) =>
    `/api/company?companyId=${companyId}`,
  getUserById: (userId: string | number) => `/api/user/${userId}/profile`,
  getStatic: "/api/statics",
  getAvailableJobs: ({
    currentPage,
    itemsPerPage,
    jobTypeId,
    miles,
    lat,
    lng,
    zip,
  }: Omit<TRequestProps, "isUserFetched">) =>
    `/api/jobs/available?pageSize=${itemsPerPage}&pageNumber=${currentPage}${
      jobTypeId ? `&jobTypeId=${jobTypeId}` : ""
    }${miles ? `&miles=${miles}` : ""}${lat ? `&lat=${lat}` : ""}${
      lng ? `&lng=${lng}` : ""
    }${zip ? `&zip=${zip}` : ""}`,
  getJobs: (currentPage: number, itemsPerPage: number) =>
    `/api/contractor/jobs?pageSize=${itemsPerPage}&pageNumber=${currentPage}`,
  getJobsByStatus: (
    currentPage: number,
    itemsPerPage: number,
    status: string,
    companyId: number,
    contractorId?: number
  ) =>
    `/api/jobs/${status}?pageSize=${itemsPerPage}&pageNumber=${currentPage}&companyId=${companyId}${
      contractorId ? `&contractorId=${contractorId}` : ""
    }`,
  getJobsByUserId: (
    userId: number,
    currentPage: number,
    itemsPerPage: number
  ) =>
    `/api/user/${userId}/jobs/history?pageSize=${itemsPerPage}&pageNumber=${currentPage}`,
  getJob: (jobId: string | number) => `/api/job/${jobId}`,
  getTeam: (companyId: string | number) => `/api/company/${companyId}/members`,
  postAuthorizeUser: "/api/contractor/login",
  postGoogleSignIn: "/api/homeowner/login/google",
  postAppleSignIn: "/api/homeowner/login/apple",
  postForgotPassword: "/api/user/password/forgot",
  postAcceptInvitation: "/api/user/accept",
  postAssignJob: (jobId: number) => `/api/job/${jobId}/assign`,
  postCancelJob: (jobId: number) => `/api/job/${jobId}/cancel`,
  postAcceptJob: (jobId: number) => `/api/job/${jobId}/accept`,
  postInviteTeamMember: "/api/user/invite",
  postUpdatePassword: "/api/user/password",
  putUpdateUser: "/api/user/profile",
  postUpdateCompany: "/api/company",
  postUpdateCompanyJobTypes: (companyId: number) =>
    `/api/company/${companyId}/job-types`,
  getStripeLink: (companyId: number) =>
    `/api/company/${companyId}/account/link`,
  putUpdateUserById: (userId: string | number) => `/api/user/${userId}/profile`,
  deleteRemoveFromTeam: (userId: string | number, companyId: string | number) =>
    `/api/company/${companyId}/member/${userId}`,
  getTitle5InspectionReport: (jobId: number) =>
    `/api/report/title5Inspection?jobId=${jobId}`,
  getSystemPumpingReport: (jobId: number) =>
    `/api/report/systemPumping?jobId=${jobId}`,
  getSepticFactsReport: (jobId: number) =>
    `/api/report/septicFacts?jobId=${jobId}`,
};

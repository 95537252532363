import React from "react";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { PresentationProps } from "./Payments.types";
import { translations } from "../../../../i18n/translations";
import { BiLinkExternal } from "react-icons/bi";

export const PaymentsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.settings.payments;
  const { isLoading, onClickCTA } = props;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Text fontSize="lg" fontWeight={600}>
          {t<string>(screen.pageTitle)}
        </Text>
        <Box mt={2}>
          <Text color="gray.600" fontSize="sm">
            {t<string>(screen.description)}
          </Text>
        </Box>
      </Flex>
      <Flex my={4}>
        <Button
          colorScheme="blue"
          isDisabled={isLoading}
          isLoading={isLoading}
          rightIcon={<Icon as={BiLinkExternal} />}
          size="sm"
          onClick={onClickCTA}
        >
          {t<string>(screen.cta)}
        </Button>
      </Flex>
    </Flex>
  );
};

import React from "react";
import { Input, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { Props } from "./ContactInformation.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { PHONE_PATTERN } from "../../../../constants/global";
import { PatternFormat } from "react-number-format";

export const ContactInformation = (props: Props) => {
  const screen = translations.screens.profile;
  const { isUpdatingProfile, isEditMode, data, onEditField, formData } = props;
  const { firstName, lastName, phoneNumber, email } = data || {};

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.firstName)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.firstName}
                  onChange={onEditField("firstName")}
                />
              ) : (
                firstName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.lastName)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.lastName}
                  onChange={onEditField("lastName")}
                />
              ) : (
                lastName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.phone)}
            </Td>
            <Td color="gray.500">
              {isEditMode && (
                <PatternFormat
                  customInput={Input}
                  defaultValue={formData.phoneNumber}
                  disabled={isUpdatingProfile}
                  format={PHONE_PATTERN}
                  onValueChange={(v) => {
                    onEditField("phoneNumber")({
                      target: { value: v.value },
                    } as any);
                  }}
                />
              )}
              {!isEditMode && (
                <PatternFormat
                  displayType="text"
                  format={PHONE_PATTERN}
                  value={phoneNumber}
                />
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.table.email)}
            </Td>
            <Td color="gray.500">{email || "-"}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

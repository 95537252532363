import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { ContactInformation } from "../ContactInformation";
import { Props } from "./PersonalDetails.types";
import { TradeLicenses } from "../TradeLicenses";

export const PersonalDetailsTab = (props: Props) => {
  const {
    data,
    formData,
    onEditField,
    isEditMode,
    isEditable,
    isUpdatingProfile,
    onCancelEditMode,
    onSaveProfile,
    onSetEditMode,
  } = props;
  const screen = translations.screens.profile;

  return (
    <>
      <br />
      <Flex justifyContent="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {t<string>(screen.contactInformation)}
        </Text>
        <Flex justifyContent="flex-end">
          {isEditMode && (
            <>
              <Button
                colorScheme="blue"
                isLoading={isUpdatingProfile}
                size="sm"
                variant="outline"
                onClick={onCancelEditMode}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Box ml={3}>
                <Button
                  colorScheme="blue"
                  isLoading={isUpdatingProfile}
                  size="sm"
                  onClick={onSaveProfile}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {!isEditMode && isEditable && (
            <Button colorScheme="blue" size="sm" onClick={onSetEditMode}>
              {t<string>(screen.edit)}
            </Button>
          )}
        </Flex>
      </Flex>
      <br />
      <ContactInformation
        data={data}
        formData={formData}
        isEditMode={isEditMode}
        isUpdatingProfile={isUpdatingProfile}
        onEditField={onEditField}
      />
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.tradeLicenses)}
      </Text>
      <br />
      <TradeLicenses
        data={data}
        formData={formData}
        isEditMode={isEditMode}
        isUpdatingProfile={isUpdatingProfile}
        onEditField={onEditField}
      />
    </>
  );
};

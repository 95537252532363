import { TStatus } from "../../../types/common/Api";

export type TCompanyAdmin = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  photoUrl: string | null;
};

export type TUserCompany = {
  companyId: number;
  companyName: string | null;
  companyCode: string | null;
  businessPhone: string | null;
  businessEmail: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  zip: string | null;
  city: string | null;
  state: string | null;
  haveOwnInsurance: boolean;
  companyAdmins: TCompanyAdmin[];
  lat: number;
  lng: number;
};

export enum AuthProviders {
  Google = "Google",
  Apple = "Apple",
  Email = "EmailPassword",
}

export type TUser = {
  userId: number;
  authProvider: AuthProviders;
  userTypeId: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  zip: string | null;
  state: string | null;
  createdDate: string | null;
  objectId: string | null;
  companyId: number | null;
  septicSystemInspectionsLicense: string | null;
  plumbingLicense: string | null;
  profilePicUrl: string | null;
  roles: string[];
  jobTitle?: string;
  rating?: number;
  company: TUserCompany;
};

export type TUserRaw = {
  data: { user: TUser };
  status: TStatus;
};

import React from "react";
import { Box, Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./CompanyDetails.types";
import { CompanyData } from "../CompanyData";
import { CompanyAdmins } from "../CompanyAdmins";
import { Payments } from "../Payments";

export const CompanyDetailsTab = (props: Props) => {
  const {
    data,
    formData,
    onEditField,
    onEditCheckboxField,
    isEditMode,
    isEditable,
    isUpdatingCompany,
    onCancelEditMode,
    onSaveCompany,
    onSetEditMode,
  } = props;
  const screen = translations.screens.profile;

  return (
    <>
      <br />
      <Flex justifyContent="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {t<string>(screen.companyInformation)}
        </Text>
        <Flex justifyContent="flex-end">
          {isEditMode && (
            <>
              <Button
                colorScheme="blue"
                isLoading={isUpdatingCompany}
                size="sm"
                variant="outline"
                onClick={onCancelEditMode}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Box ml={3}>
                <Button
                  colorScheme="blue"
                  isLoading={isUpdatingCompany}
                  size="sm"
                  onClick={onSaveCompany}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {!isEditMode && isEditable && (
            <Button colorScheme="blue" size="sm" onClick={onSetEditMode}>
              {t<string>(screen.edit)}
            </Button>
          )}
        </Flex>
      </Flex>
      <br />
      <CompanyData
        data={data}
        formData={formData}
        isEditMode={isEditMode}
        isUpdatingCompany={isUpdatingCompany}
        onEditField={onEditField}
      />
      <br />
      <Payments />
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.insurance)}
      </Text>
      <br />
      <Text color="gray.500">{t<string>(screen.insuranceDescription)}</Text>
      <br />
      <Checkbox
        checked={formData.haveOwnInsurance}
        defaultChecked={data?.haveOwnInsurance}
        isDisabled={!isEditMode}
        onChange={onEditCheckboxField("haveOwnInsurance")}
      >
        {t<string>(screen.hasInsurance)}
      </Checkbox>
      <br />
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.companyAdmins)}
      </Text>
      <CompanyAdmins data={data?.companyAdmins || []} />
    </>
  );
};

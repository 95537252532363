import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  VStack,
  Text,
  useToken,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import { Props } from "./Navigation.types";
import { FiChevronDown, FiMenu } from "react-icons/fi";
import { BsGearFill } from "react-icons/bs";
import Logo from "../Logo";
import { PortalsContext } from "../../contexts/PortalsContext";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { useLogout } from "../../utils/logout";
import { useUser } from "../../api/modules/User";
import { getProfileImageUrl } from "../../utils/getProfileImageUrl";
import { Link, useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const Navigation = ({ onOpen, ...rest }: Props) => {
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const { data } = useUser();
  const handleNavigateProfile = useCallback(() => {
    navigate(Routing.Profile);
  }, [navigate]);
  const [gray100, gray500, gray700] = useToken("colors", [
    "gray.100",
    "gray.500",
    "gray.700",
  ]);
  const { breadcrumbsRef } = useContext(PortalsContext);
  return (
    <Flex
      alignItems="center"
      bg={{ base: "gray.800", md: "gray.50" }}
      height="16"
      justifyContent="space-between"
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      {...rest}
    >
      <Flex display={{ base: "none", md: "block" }} fontSize="12">
        <div ref={breadcrumbsRef} />
        {/*<Text ref={pageTitleRef} fontSize="md" fontWeight={600} />*/}
      </Flex>
      <IconButton
        aria-label="open menu"
        display={{ base: "flex", md: "none" }}
        icon={<FiMenu color={gray100} />}
        variant="outline"
        onClick={onOpen}
      />
      <Box display={{ base: "block", md: "none" }}>
        <Logo width="100px" />
      </Box>
      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              _focus={{ boxShadow: "none" }}
              py={2}
              transition="all 0.3s"
            >
              <HStack>
                <Avatar size="sm" src={getProfileImageUrl(data)} />
                <VStack
                  alignItems="flex-start"
                  display={{ base: "none", md: "flex" }}
                  ml="2"
                  spacing="1px"
                >
                  <Text fontSize="sm">{`${data?.firstName || ""} ${
                    data?.lastName || ""
                  }`}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="gray.800" borderColor={gray500} color={gray100}>
              <MenuItem
                _focus={{ bg: "gray.700" }}
                bg="gray.900"
                onClick={handleNavigateProfile}
              >
                {t<string>(translations.common.profileMenu.profile)}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                _focus={{ bg: "gray.700" }}
                bg="gray.900"
                onClick={handleLogout}
              >
                {t<string>(translations.common.profileMenu.signOut)}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Flex>
          {/*<IconButton*/}
          {/*  _active={{ backgroundColor: "transparent" }}*/}
          {/*  _hover={{ backgroundColor: "transparent" }}*/}
          {/*  aria-label="open menu"*/}
          {/*  display={{ base: "flex", md: "none" }}*/}
          {/*  icon={<BsBellFill color={gray100} />}*/}
          {/*  size="md"*/}
          {/*  variant="ghost"*/}
          {/*/>*/}
          {/*<IconButton*/}
          {/*  aria-label="open menu"*/}
          {/*  display={{ base: "none", md: "flex" }}*/}
          {/*  icon={<BsBellFill color={gray700} />}*/}
          {/*  size="md"*/}
          {/*  variant="ghost"*/}
          {/*/>*/}
          <Link to={Routing.Settings.LoginInformation.Absolute}>
            <IconButton
              _active={{ backgroundColor: "transparent" }}
              _hover={{ backgroundColor: "transparent" }}
              aria-label="open menu"
              display={{ base: "flex", md: "none" }}
              icon={<BsGearFill color={gray100} />}
              size="md"
              variant="ghost"
            />
          </Link>
          <Link to={Routing.Settings.LoginInformation.Absolute}>
            <IconButton
              aria-label="open menu"
              display={{ base: "none", md: "flex" }}
              icon={<BsGearFill color={gray700} />}
              size="md"
              variant="ghost"
            />
          </Link>
        </Flex>
      </HStack>
    </Flex>
  );
};

import React from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { Props } from "./CompanyData.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { getAddressCopy } from "../../../../utils/Company/getAddressCopy";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";

export const CompanyData = (props: Props) => {
  const screen = translations.screens.profile.companyDetailsData;
  const { data, formData, onEditField, isEditMode, isUpdatingCompany } = props;
  const { companyName, businessPhone, businessEmail } = data || {};

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.title)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingCompany}
                  value={formData.companyName}
                  onChange={onEditField("companyName")}
                />
              ) : (
                companyName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.phone)}
            </Td>
            <Td color="gray.500">
              {isEditMode && (
                <PatternFormat
                  customInput={Input}
                  defaultValue={formData.businessPhone}
                  disabled={isUpdatingCompany}
                  format={PHONE_PATTERN}
                  onValueChange={(v) => {
                    onEditField("businessPhone")({
                      target: { value: v.value },
                    } as any);
                  }}
                />
              )}
              {!isEditMode && (
                <PatternFormat
                  displayType="text"
                  format={PHONE_PATTERN}
                  value={businessPhone}
                />
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.email)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingCompany}
                  value={formData.businessEmail}
                  onChange={onEditField("businessEmail")}
                />
              ) : (
                businessEmail || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="250px">
              {t<string>(screen.address)}
            </Td>
            {/*<Td color="gray.500">{t<string>(screen.companyAddress1)}</Td>*/}
            <Td color="gray.500">
              {isEditMode ? (
                <Flex flexDirection="column">
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="addressLine1">
                      {t<string>(screen.companyAddress1)}
                    </FormLabel>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.addressLine1}
                      onChange={onEditField("addressLine1")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <FormLabel fontSize="sm" htmlFor="companyAddress2">
                      {t<string>(screen.companyAddress2)}
                    </FormLabel>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.addressLine2}
                      onChange={onEditField("addressLine2")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <FormLabel fontSize="sm" htmlFor="zip">
                      {t<string>(screen.zip)}
                    </FormLabel>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.zip}
                      onChange={onEditField("zip")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <FormLabel fontSize="sm" htmlFor="city">
                      {t<string>(screen.city)}
                    </FormLabel>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.city}
                      onChange={onEditField("city")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <FormLabel fontSize="sm" htmlFor="state">
                      {t<string>(screen.state)}
                    </FormLabel>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.state}
                      onChange={onEditField("state")}
                    />
                  </FormControl>
                </Flex>
              ) : (
                getAddressCopy(data)
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
